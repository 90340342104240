.checkboxContainer {
  position: relative;

  &.inactive {
    pointer-events: none;
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  input[type="checkbox"] {
    display: none;

    &:checked {
      ~ .checkbox {
        background: #3d734a;
        border-color: #3d734a;
      }
    }
  }

  .checkbox {
    min-height: 20px;
    min-width: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .content {
    font-size: 12px;
    line-height: 15px;
    color: rgba(102, 102, 102, 0.7);
    margin-left: 10px;
    flex: 1;

    strong {
      color: #666;
    }
  }
}
