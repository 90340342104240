.tabs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 20px;

  .tab {
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    width: 100%;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.17);
    cursor: pointer;

    @media screen and (max-width: 380px) {
      flex-direction: column;
    }

    &.selected {
      background: rgba(61, 115, 74, 0.12);
      border: none;

      p {
        color: #3d734a;
      }

      &.defineCity {
        svg path {
          stroke: #95b39c;
        }
      }

      &.useLocation {
        svg path {
          fill: #95b39c;
        }
      }
    }

    p {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
      margin-left: 15px;

      @media screen and (max-width: 380px) {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}

.currentLocation {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
  margin-bottom: 20px;

  .address {
    background: rgba(61, 115, 74, 0.1);
    border-radius: 15px;
    padding: 10px;
    display: flex;
    align-items: center;
    color: #3d734a;
    font-size: 14px;
    line-height: 22px;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    svg {
      margin-right: 10px;
    }
  }
}

.nearUnits {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #4d4d4d;
  margin-top: 20px;
  margin-bottom: 20px;

  strong {
    font-weight: 600;
    color: #404040;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 100px);
}

.emptyUnits {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 100px);

  .picture {
    width: 86px;
    height: 65px;
    position: relative;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    margin-top: 20px;
    text-align: center;
  }
}

.geolocationContainer {
  padding: 35px 15px;
  height: 100%;

  @media screen and (min-width: 992px) {
    padding: 35px 50px;
  }
}

.citiesContainer {
  padding: 35px 15px;
  height: 100%;

  @media screen and (min-width: 992px) {
    padding: 35px 50px;
  }

  .cities {
    margin-top: 30px;
    padding-top: 20px;
    margin-left: -15px;
    margin-right: -15px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    @media screen and (min-width: 992px) {
      margin-left: -50px;
      margin-right: -50px;
    }

    .city {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 10px 15px;

      @media screen and (min-width: 992px) {
        padding: 10px 50px;
      }

      @for $i from 1 through 3 {
        &:nth-child(1n + #{$i}) {
          animation: animateTopTop 0.5s both $i * 0.15s;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      &:hover {
        background: #f7f7f7;
      }

      .icon {
        background: #f2f2f2;
        min-width: 35px;
        min-height: 35px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
      }

      .cityName {
        font-size: 14px;
        line-height: 22px;
        color: #666666;
        margin-bottom: 5px;
      }

      .unitsCity {
        color: rgba(0, 0, 0, 0.35);
        font-size: 14px;
        line-height: 22px;
        font-weight: 300;
      }
    }
  }
}

.unitsContainer {
  height: 100%;

  .selectedCity {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 20px;
    margin-bottom: 20px;

    .city {
      background: rgba(61, 115, 74, 0.1);
      border-radius: 15px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #3d734a;
      font-size: 14px;
      line-height: 22px;

      svg {
        margin-right: 10px;
      }
    }
  }

  .units {
    margin-top: 20px;
    height: calc(100% - 250px);
    overflow-y: auto;
    padding-right: 10px;
    margin-right: -10px;

    .unit {
      display: flex;
      align-items: center;
      background: #ffffff;
      border-radius: 20px;
      position: relative;
      cursor: pointer;
      overflow: hidden;

      @for $i from 1 through 3 {
        &:nth-child(1n + #{$i}) {
          animation: animateTopTop 0.5s both $i * 0.15s;
        }
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      &.selected {
        .details {
          border-color: #3d734a;
        }
      }

      &.closed {
        pointer-events: none;
        cursor: not-allowed;

        .details {
          opacity: 0.5;

          .icon {
            background: #fdf2f2;

            svg path {
              fill: #f2a5a3;
            }
          }
        }
      }

      .picture {
        height: 150px;
        position: relative;

        img {
          object-fit: cover;
        }
      }

      .details {
        display: flex;
        align-items: center;
        padding: 15px 20px;
        border: 1px solid rgba(130, 130, 130, 0.2);
        border-radius: 20px;

        .icon {
          background: #f2f2f2;
          min-width: 45px;
          min-height: 45px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;

          svg path {
            fill: #a0a0a0;
          }
        }

        .name {
          font-weight: 500;
          margin-bottom: 5px;
          font-size: 14px;
          line-height: 20px;
          color: #666666;
        }

        .status {
          font-weight: 300;
          margin-bottom: 5px;
          font-size: 12px;
          line-height: 20px;
          color: #a0a0a0;
          margin-left: 5px;
        }

        .address {
          font-weight: 300;
          font-size: 14px;
          line-height: 21px;
          color: rgba(0, 0, 0, 0.35);
        }

        .delivery {
          font-size: 12px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.35);
          margin-top: 5px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.alertTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #404040;
  margin-bottom: 5px;
}

.alertSubtitle {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 25px;
}

.footer {
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  animation: animateTopTop 0.5s both;

  @media screen and (min-width: 992px) {
  }
}

.withdrawalType {
  background: #e4e8e5;
  border-radius: 15px;
  padding: 3px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #3d734a;
}

.deliveryTypes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 35px;
  margin-top: 35px;

  .type {
    background: #fcfcfc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    transition: all 0.25s ease;
    position: relative;

    &.selected {
      border: 1px solid rgba(61, 115, 74, 0.55);

      .icon {
        background: rgba(61, 115, 74, 0.15);

        svg {
          transform: scale(1.1);

          path {
            fill: #3d734a;
            stroke: #3d734a;
          }
        }
      }

      .checked {
        opacity: 1;
      }
    }

    .checked {
      background: #3d734a;
      width: 14px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: all 0.25s ease;
      position: absolute;
      right: 20px;
      top: 20px;
      border-radius: 50%;
    }

    .icon {
      background: #f0f0f0;
      border-radius: 15px;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      svg {
        path {
          fill: rgba(0, 0, 0, 0.4);
          stroke: rgba(0, 0, 0, 0.4);
        }
      }
    }

    .name {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: rgba(64, 64, 64, 0.8);
    }
  }
}

.errorMessage {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #333333;
}

@keyframes animateTopTop {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
