.scrollContainer {
  height: calc(100% - 110px);
  overflow-y: auto;
  width: 100%;

  &.isDigitalMenu {
    height: 100%;
  }

  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border: 6px solid #3d734a;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    border: 6px solid #e5e5e5;
    border-radius: 20px;
  }

  > div {
    z-index: 2;
  }

  .noPictureClose {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    z-index: 1;
    cursor: pointer;
    background: #f7f7f7;
    border-radius: 10px;
    width: 42px;
    height: 42px;
    margin-top: 20px;
    margin-right: 20px;

    &:hover {
      background: #e2e2e2;
    }
  }

  .picture {
    height: 250px;
    position: relative;

    img {
      object-fit: cover;
    }

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 20px;
      right: 20px;
      margin: auto;
      z-index: 1;
      cursor: pointer;
      background: #f7f7f7;
      border-radius: 10px;
      width: 42px;
      height: 42px;

      &:hover {
        background: #e2e2e2;
      }
    }
  }

  .productDetailsContainer {
    padding: 25px 20px;

    .productDetails {
      .productName {
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        color: #404040;
        margin-bottom: 20px;
      }

      .productDescription {
        font-size: 12px;
        line-height: 20px;
        color: rgba(132, 132, 132, 0.75);
      }

      .fromPrice {
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        color: #404040;
        margin-bottom: 20px;
        margin-top: 20px;

        strike {
          text-decoration: line-through;
          color: #000;
          font-weight: 400;
          opacity: 0.5;
          font-size: 16px;
        }
      }
    }

    .sectionHeader {
      padding: 15px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ededed;
      width: calc(100% + 40px);
      margin-left: -20px;
      position: relative;
      min-height: 60px;

      .sectionTitle {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: rgba(64, 64, 64, 0.8);
      }

      .maxQuantity,
      .minQuantity {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: rgba(64, 64, 64, 0.45);
        margin-top: 5px;
      }

      .requiredField {
        background: #d1d1d1;
        border-radius: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: rgba(0, 0, 0, 0.45);
        padding: 3px 10px;
        text-transform: uppercase;
        margin-left: 10px;
        min-height: 26px;
      }
    }

    .optionItems {
      .optionItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding-bottom: 15px;
        cursor: pointer;
        min-height: 75px;

        &:nth-child(1) {
          padding-top: 20px;
        }

        &:last-child {
          padding-bottom: 20px;
        }

        &:not(:first-child) {
          padding-top: 15px;
          border-top: 1px solid rgba(0, 0, 0, 0.08);
        }

        .image {
          position: absolute;
          right: 45px;
          z-index: -1;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          overflow: hidden;
        }

        .optionName {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #404040;
        }

        .description {
          font-size: 14px;
          line-height: 17px;
          margin-top: 5px;
          color: rgba(0, 0, 0, 0.35);
          font-weight: 300;
        }

        .optionOldPrice {
          font-size: 12px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.45);
          text-decoration: line-through;
          margin-right: 5px;
        }

        .optionSelect {
          margin-left: 30px;
          min-width: 105px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        .optionPrice {
          font-size: 14px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.7);
          font-weight: 500;
        }
      }
    }

    .sizes {
      background: #ffffff;
      box-shadow: 0px 8px 68px 45px rgba(153, 153, 153, 0.09);
      border-radius: 10px;
      padding: 20px;
      margin-top: 25px;
      margin-bottom: 25px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .sizePrice {
        font-weight: 700;
        font-size: 12px;
        line-height: 17px;
        color: #404040;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        flex: 0 100%;
        display: flex;
        align-items: center;
      }

      .size {
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 200px;
        display: flex;
        min-width: 41px;
        min-height: 41px;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 5px;
        }

        &.selected {
          background: #3d734a;

          .name {
            color: #fff;
          }
        }

        .name {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: rgba(132, 132, 132, 0.75);
        }
      }
    }

    .flavors {
      .quantities {
        display: flex;
        flex-direction: row;
        .quantity {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          background: #ffffff;
          color: rgba(0, 0, 0, 0.8);
          border-radius: 15px;
          padding: 20px;
          border: 1px solid rgba(130, 130, 130, 0.2);
          position: relative;
          cursor: pointer;

          &.selected {
            border: 1px solid rgba(61, 115, 74, 0.45);

            .icon {
              background: #e7eee9;

              svg path {
                fill: #3d734a;
              }
            }
          }
        }
      }
    }

    .extras {
      background: #fff;
      padding: 15px;
      border-radius: 15px;

      .extra {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
          margin-bottom: 15px;
        }

        .price {
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #4c4c4c;
        }
      }
    }

    .iDontWant {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #737373;
      padding: 15px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      margin-top: 20px;
    }
  }
}

.overviewContainer {
  background: #ffffff;
  box-shadow: 0px 8px 68px 45px rgba(153, 153, 153, 0.09);
  border-radius: 10px;

  .overviewHeader {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;

    .productImage {
      width: 84px;
      height: 43px;
      border-radius: 15px;
      margin-right: 15px;
      position: relative;
      overflow: hidden;

      img {
        object-fit: cover;
      }
    }

    .productName {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #404040;
    }
  }

  .overviewContent {
    padding: 20px 0;
    margin-bottom: 20px;

    .stepContent {
      padding: 0 5px 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      }

      .stepName {
        font-size: 12px;
        line-height: 15px;
        color: rgba(102, 102, 102, 0.7);
        margin-bottom: 10px;
      }

      .stepChoices {
        .question {
          display: flex;
          flex-direction: column;

          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }

        p {
          font-weight: 600;
          font-size: 13px;
          color: #666666;
        }

        span {
          font-size: 12px;
          line-height: 15px;
          margin-top: 5px;
          color: rgba(0, 0, 0, 0.5);
        }
      }

      .goToStep {
        padding: 0 15px;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }
  }
}

.productOverview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 0;

  p {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #404040;
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    background: #f7f7f7;
    border-radius: 10px;
    width: 42px;
    height: 42px;

    &:hover {
      background: #e2e2e2;
    }
  }
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 15px 25px 15px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background: #fcfcfc;
  touch-action: none;
  width: 100%;

  .quantityContainer {
    background: #fcfcfc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    min-width: 120px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin-right: 20px;

    @media screen and (max-width: 380px) {
      min-width: 120px;
      margin-right: 10px;
    }

    .minus,
    .plus {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex: 1;

      &.disabled {
        opacity: 0.5;
      }

      &.enabled {
        cursor: pointer;
      }
    }

    .qty {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .continueChevron {
    position: absolute;
    right: 20px;
  }
}

.obsLength {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
  margin-top: 5px;
}

.promoPrice {
  text-decoration: line-through;
  color: #000;
  font-weight: 400;
  opacity: 0.5;
  font-size: 12px;
  line-height: 17px;
}
