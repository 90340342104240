.layoutContainer {
  max-width: 100%;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  background-color: #fcfcfc;

  .content {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  .clubContent {
    width: 500px;
    max-width: 100%;
    margin: auto;
    height: 100%;
    position: relative;
    margin-top: 80px;
  }
}
