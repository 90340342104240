.scrollContainer {
  height: 100%;
  overflow-y: auto;
  padding-right: 20px;
  margin-right: -20px;
  padding-bottom: 10px;

  &.limitHeight {
    height: calc(100% - 50px);
  }
}

.benefitsList {
  .emptyCoupons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
    }
  }
}

.footer {
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  @media screen and (min-width: 992px) {
  }
}

.alertTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #404040;
  margin-bottom: 5px;
}

.alertSubtitle {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 25px;
}

.selectBenefit {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  strong {
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.65);
    margin-left: 10px;
  }
}

.useBalance {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: -1px 25px 32px -9px rgba(15, 15, 15, 0.04);
  border-radius: 20px;
  margin-bottom: 20px;
  margin-top: 10px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.disabled {
    .text,
    .value {
      opacity: 0.5;
    }
  }

  .details {
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .notAvailable {
    background: #fafafa;
    border-radius: 0px 0px 15px 15px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    color: #828282;
    width: 100%;
  }

  .text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.55);
    margin-bottom: 5px;
  }

  .value {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.55);
  }

  &.selected {
    border: 1px solid #3d734a;

    .text {
      color: #3d734a;
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.75);
  margin-bottom: 5px;
}

.subtitle {
  font-size: 14px;
  line-height: 22px;
  color: rgba(89, 89, 89, 0.65);
}
