.paymentsList {
  width: 100%;
  height: calc(100% - 50px);

  .scrollContainer {
    height: calc(100% - 50px);

    .onlinePayments {
      height: calc(100% - 150px);
      overflow-y: auto;
      padding-bottom: 10px;
      padding-right: 15px;
      margin-right: -15px;
    }

    .offlinePayments {
      height: 100%;
      overflow-y: auto;
      padding-bottom: 10px;
      padding-right: 15px;
      margin-right: -15px;
    }

    &.withTabs {
      .onlinePayments {
        height: calc(100% - 80px);
      }
    }
  }

  .offlinePayments {
    .title {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
      margin-bottom: 20px;

      &:not(:first-child) {
        margin-top: 25px;
      }
    }

    .paymentCard {
      border-radius: 15px;
      display: flex;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 20px;
      cursor: pointer;

      &:not(:first-child) {
        margin-top: 10px;
      }

      .icon {
        width: 32px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        border-radius: 5px;
      }

      .alias {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #666666;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }

  .onlinePayments {
    height: 100%;
    display: flex;
    flex-direction: column;

    .hairline {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #e6e6e6;
    }

    .empty {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 1;

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #666666;
        margin-top: 20px;
      }
    }

    .pixContainer {
      margin-bottom: 15px;

      .pix {
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px;
        cursor: pointer;

        &.selected {
          border: 1px solid rgba(61, 115, 74, 0.6);
        }

        .icon {
          min-width: 32px;
          min-height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 20px;
          position: relative;
        }

        .alias {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #666666;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }

    .paymentCard {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      display: flex;
      align-items: stretch;
      border: 1px solid transparent;

      &.selected {
        border: 1px solid rgba(61, 115, 74, 0.6);
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .details {
        display: flex;
        align-items: center;
        padding: 20px 0 20px 20px;
        border-radius: 15px;
        background: #ffffff;
        z-index: 1;
        width: 100%;

        @media screen and (max-width: 380px) {
          padding: 10px 0 10px 10px;
        }

        .icon {
          min-width: 32px;
          min-height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 20px;
          border-radius: 5px;

          @media screen and (max-width: 380px) {
            margin-right: 10px;
          }
        }

        .alias {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #666666;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          margin-bottom: 5px;
        }

        .number {
          font-size: 14px;
          line-height: 18px;
          color: #999999;
        }

        .selected {
          display: flex;
          align-items: center;
        }
      }

      .ellipsis {
        cursor: pointer;
        padding: 5px;
        transition: transform 0.25s ease;
        min-width: 50px;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.rotate {
          transform: rotate(90deg);
          margin-left: 5px;
        }
      }

      .remove {
        padding: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-right: 5px;
        margin-left: 5px;
      }

      .removeContainer {
        display: none;
        align-items: center;
        justify-content: center;
        padding: 0 15px 0 30px;
        transform: translateX(-15px);
        margin-right: -15px;
        border: 1px solid transparent;
        background: #f8f8f8;
        border-radius: 0 15px 15px 0;

        &.visible {
          display: flex;
        }
      }
    }
  }
}

.paymentMethod {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 68px 45px rgba(153, 153, 153, 0.04);
  border-radius: 15px;
  padding: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .icon {
    background: rgba(0, 0, 0, 0.06);
    margin-right: 20px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .name {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.footer {
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  @media screen and (min-width: 992px) {
  }
}
