$primary-color: #3d734a;
$red-color: #d91604;
$yellow-color: #f2b950;

:export {
  primaryColor: $primary-color;
  redColor: $red-color;
  yellowColor: $yellow-color;
}

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Inter", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none;
}

body.ReactModal__Body--open {
  overflow-y: hidden;
}

@media screen and (min-width: 992px) {
  body {
    height: 100vh;
    overflow-y: auto;
  }
}

@media screen and (max-width: 991px) {
  ::-webkit-scrollbar {
    width: 0;
  }
}

::selection {
  background-color: $primary-color;
  color: #ffffff;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  border: 5px solid $primary-color;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  border: 5px solid #e5e5e5;
  border-radius: 20px;
}

a {
  color: inherit;
  text-decoration: none;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.align-stretch {
  align-items: stretch;
}

.align-start {
  align-items: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-end {
  justify-content: flex-end;
}

.flex-col {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.m-20 {
  margin: 20px;
}

.relative {
  position: relative;
}

.pointer {
  cursor: pointer;
}

#swiper-pagination-banners-home {
  display: flex;
  align-items: center;

  .swiper-pagination-bullet {
    transition: width 0.5s ease !important;
    opacity: 1 !important;
    width: 10px !important;
    height: 10px !important;
    border-radius: 50% !important;
    margin: 0 2.5px !important;
    background: rgba(61, 115, 74, 0.25) !important;
  }

  .swiper-pagination-bullet-active {
    width: 20px !important;
    border-radius: 20px !important;
    background: #3d734a !important;
  }
}

#swiper-pagination-banners-brand {
  position: absolute;
  bottom: 40px;
  z-index: 1;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;

  .swiper-pagination-bullet {
    transition: width 0.5s ease;
    opacity: 1;
    width: 6px;
    height: 6px;
    border-radius: 12px;
    margin: 0 2.5px;
    background: rgba(255, 255, 255, 0.45);
  }

  .swiper-pagination-bullet-active {
    width: 20px;
    background: #ffffff;
  }
}
