.content-load {
  position: fixed;
  top: 30px;
  left: 60px;
  width: calc(100vw - 60px);
  height: calc(100vh - 30px);
  z-index: 500;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 20px;
}

.full-loading {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
}

@media screen and (max-width: 991px) {
  .content-load {
    top: 0px;
    left: 0;
    height: 100%;
    width: 100vw;
    border-top-left-radius: 0;
    background: transparent;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  z-index: 999;
}

.lds-ellipsis div {
  position: absolute;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}
