.headerContainerDelivery {
  header {
    justify-content: space-between;
  }
}

.headerContainerReceiveOn {
  header {
    justify-content: flex-end;
  }
}

:is(.headerContainerDelivery, .headerContainerReceiveOn) {
  z-index: 5;
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  height: 70px;

  @media screen and (max-width: 991px) {
    display: none;
  }

  header {
    display: flex;
    align-items: center;
    padding: 15px;
    position: relative;
    width: 1140px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;

    .addressContainer {
      display: flex;
      align-items: center;

      .address {
        padding: 10px 0;
        display: flex;
        width: 220px;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        p {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: rgba(115, 115, 115, 0.85);
          margin-left: 15px;
          margin-right: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          flex: 1;
        }

        .icon {
          min-width: 11px;
          display: flex;
          align-items: center;
        }
      }

      .hairline {
        margin: 7.5px 20px;
        width: 0.5px;
        height: 25px;
        background: rgba(0, 0, 0, 0.15);
      }
    }

    nav {
      display: flex;
      align-items: center;

      > ul {
        display: flex;
        height: 100%;
        list-style: none;

        li {
          font-weight: 600;
          font-size: 12px;
          color: rgba(115, 115, 115, 0.65);
          position: relative;
          cursor: pointer;
          margin-right: 20px;
          display: flex;
          align-items: center;

          .iconReload {
            display: flex;
            align-items: center;
          }

          &:hover,
          &.active {
            color: #3d734a;

            svg path {
              fill: #3d734a;
              stroke: #3d734a;
            }

            .iconReload {
              svg path {
                stroke: #3d734a;
                fill: none;
              }
            }
          }

          .icon {
            position: relative;
            margin-right: 15px;

            svg {
              margin-right: 0;
            }
          }

          svg {
            margin-right: 15px;
          }

          &:after {
            content: "";
            height: 2px;
            margin: auto;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            transition: all 0.3s ease 0s;
            width: 0;
            display: block;
            border-radius: 30px;
          }

          a,
          p {
            height: 100%;
            display: flex;
            align-items: center;
          }

          .quantity {
            position: absolute;
            z-index: 1;
            bottom: 3px;
            right: -10px;
            min-width: 20px;
            line-height: 12px;
            font-size: 10px;
            background: #3d734a;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 400px;
            color: #fff;
          }
        }

        .hairline {
          width: 0.5px;
          height: 22px;
          margin: auto 10px auto 0;
          background: rgba(0, 0, 0, 0.15);
        }
      }

      .gift,
      .bag,
      .user {
        display: flex;
        align-items: center;
        margin-left: 10px;
        cursor: pointer;
        position: relative;
      }

      .gift {
        margin-right: 10px;
      }

      .bag {
        background: #f7f7f7;
        border-radius: 20px;
        padding: 0 15px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        .quantity {
          position: absolute;
          left: 22px;
          top: 7px;
          border-radius: 400px;
          background: #3d734a;
          z-index: 1;
          padding: 0 7px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          line-height: 12px;
          color: #ffffff;
          height: 12px;
        }

        .total {
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          color: #999999;
          margin-left: 18px;
        }
      }

      .user {
        margin-left: 15px;

        .name {
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          text-align: right;
          margin-left: 10px;
          color: #3d734a;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          max-width: 60px;
        }
      }
    }
  }
}

.fade {
  &.in {
    opacity: 1;
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &.out {
    pointer-events: none;
    opacity: 0;
  }
}

.alertTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #404040;
  margin-bottom: 5px;
}

.alertSubtitle {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 25px;
}
