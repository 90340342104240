.storeStatusContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  .storeLogo {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;

    img {
      object-fit: contain;
    }
  }

  .storeName {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    margin-top: 10px;
  }

  .unitName {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: rgba(102, 102, 102, 0.7);
    margin-top: 5px;
  }

  .storeStatus {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    &.opened {
      .dot {
        background: rgba(61, 115, 74, 0.61);
      }

      p {
        color: rgba(61, 115, 74, 0.61);
      }
    }

    &.closed {
      .dot {
        background: rgba(222, 11, 1, 0.61);
      }

      p {
        color: rgba(222, 11, 1, 0.61);
      }
    }

    .dot {
      width: 5px;
      height: 5px;
      margin-right: 5px;
      border-radius: 50%;
    }

    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }
  }
}
