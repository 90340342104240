.openCommand {
  margin-top: 30px;

  .icon {
    background: rgba(61, 115, 74, 0.1);
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #404040;
    margin-bottom: 5px;
  }

  .subtitle {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 500;
    display: block;
  }

  .hairline {
    background: rgba(0, 0, 0, 0.1);
    height: 1px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .footer {
    padding: 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    @media screen and (min-width: 992px) {
    }
  }
}

.alertTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #404040;
  margin-bottom: 25px;
}
