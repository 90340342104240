.helpContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0 30px 10px rgb(153 153 153 / 10%);
  border-radius: 10px;
  padding: 30px 15px;

  .storeLogo {
    width: 120px;
    height: 120px;
    position: relative;
    margin-bottom: 15px;
    border-radius: 50%;
    overflow: hidden;
  }

  .storeName {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #595959;
  }

  .unitName {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: rgba(102, 102, 102, 0.7);
    margin-top: 5px;
  }

  .helpDescription {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #808080;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px dashed #e6e6e6;
    margin-top: 10px;
  }

  .contacts {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 10px;

    svg {
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .phone,
    .email {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #737373;
      margin-left: 10px;
    }
  }
}
