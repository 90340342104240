.applyBenefitOptions {
  overflow-y: auto;
  height: calc(100% - 100px);

  @media screen and (min-width: 992px) {
    padding: 35px 50px 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .options {
    margin-top: 25px;

    .option {
      border-radius: 30px;
      padding: 30px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      @for $i from 1 through 3 {
        &:nth-child(1n + #{$i}) {
          animation: animateTopTop 0.5s both $i * 0.15s;
        }
      }

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      &.selected {
        background: rgba(61, 115, 74, 0.15);
        border: 1px solid transparent;

        .icon {
          background: rgba(61, 115, 74, 0.15);

          svg {
            width: 30px;
            height: 30px;

            path {
              fill: rgba(61, 115, 74, 1);
            }
          }
        }

        .name {
          color: #3d734a;
        }
      }

      .icon {
        width: 65px;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background-color: #f0f0f0;
        margin-bottom: 20px;

        svg {
          width: 24px;
          height: 24px;
          transition: all 0.25s ease;

          path {
            fill: #a0a0a0;
          }
        }
      }

      .name {
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        color: #4d4d4d;
      }
    }
  }
}

.nameContainer {
  margin-top: 15px;

  .icon {
    background: rgba(61, 115, 74, 0.1);
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #404040;
    margin-bottom: 5px;
  }

  .subtitle {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 500;
    display: block;
  }

  .hairline {
    background: rgba(0, 0, 0, 0.1);
    height: 1px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}

.enterPhoneContainer {
  margin-top: 15px;

  .icon {
    background: rgba(61, 115, 74, 0.1);
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #404040;
    margin-bottom: 5px;

    span {
      color: #3d734a;
    }
  }

  .subtitle {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 500;
    display: block;
  }

  .hairline {
    background: rgba(0, 0, 0, 0.1);
    height: 1px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}

.benefitApplied {
  margin-top: 15px;

  .label {
    margin-bottom: 35px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
  }

  .benefit {
    background: #e7ece8;
    border: 1px dashed rgba(61, 115, 74, 0.5);
    border-radius: 20px;
    padding: 20;
    width: 100%;
    padding: 20px;

    .name {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #3d734a;
      margin-bottom: 10px;
    }

    .pin {
      background: rgba(61, 115, 74, 0.1);
      border-radius: 30px;
      padding: 5px 25px;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #3d734a;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.title {
  margin-top: 15px;
  color: #404040;
  text-align: center;
  font-size: 22px;
  line-height: 34px;
  margin-bottom: 10px;
  font-weight: 600;

  span {
    color: #3d734a;
  }
}

.subtitle {
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  text-align: center;
}

.alertTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #404040;
  margin-bottom: 5px;
}

.alertSubtitle {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 25px;
}

.footer {
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  animation: animateTopTop 0.5s both;

  @media screen and (min-width: 992px) {
  }
}

@keyframes animateTopTop {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
