.title {
  margin-top: 15px;
  color: #404040;
  text-align: center;
  font-size: 22px;
  line-height: 34px;
  font-weight: 600;
  margin-bottom: 35px;
  padding-bottom: 35px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.totalsContainer {
  background: #e6e6e6;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 20px;

  ul {
    margin-bottom: 20px;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      p {
        color: #666666;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        &.benefit {
          font-weight: 600;
          color: #3d734a;
        }
      }

      span {
        color: #666666;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        &.benefit {
          font-weight: 700;
          color: #3d734a;
        }

        &.total {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }

  .gift {
    background: rgba(61, 115, 74, 0.15);
    border-radius: 30px;
    display: flex;
    align-items: center;
    padding: 5px 10px;

    .icon {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      background: #b7c6bb;
      border-radius: 50%;

      svg path {
        stroke: #3d734a;
      }
    }

    p {
      color: #3d734a;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;

      strong {
        font-weight: 700;
      }
    }
  }
}

.options {
  .label {
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
    margin-bottom: 20px;
    line-height: 24px;
  }

  .option {
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &.selected {
      border: 1px solid rgba(61, 115, 74, 0.35);

      .icon {
        background: rgba(61, 115, 74, 0.15);
      }
    }

    .icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      background: #f0f0f0;
      border-radius: 15px;

      svg {
        width: 18px;
      }
    }

    p {
      color: #666666;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.footer {
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  animation: animateTopTop 0.5s both;

  @media screen and (min-width: 992px) {
  }
}
