.drawer-base {
  > .fade {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1002;
    touch-action: none;
    opacity: 0;

    &:not(.withoutTransition),
    &:not(.withoutFade) {
      transition: opacity 0.4s ease;
    }

    @media screen and (max-width: 991px) {
      background: rgba(46, 46, 55, 0.5);
    }

    @media screen and (min-width: 992px) {
      background: rgba(46, 46, 55, 0.25);
    }

    &.withBackground {
      background: rgba(46, 46, 55, 0.5);
    }

    &.withoutFade {
      background: transparent;
    }
  }

  > .drawer {
    max-width: 100%;
    position: fixed;
    z-index: 1002;
    overscroll-behavior: contain;
    display: flex;
    flex-direction: column;
    background: #fff;
    overflow: hidden;

    &:not(.withoutTransition) {
      transition: transform 0.4s ease;
    }

    &.bottom-center {
      max-width: calc(100vw - 20px);
      width: 500px;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 25px;
      -webkit-transform: translateY(100vh);
      -ms-transform: translateY(100vh);
      transform: translateY(100vh);
    }

    &.top-full {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      height: 100%;
      -webkit-transform: translateY(100vh);
      -ms-transform: translateY(100vh);
      transform: translateY(100vh);
    }

    &.bottom-full {
      bottom: 0;
      right: 0;
      height: 100%;
      -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
      transform: translateY(100%);

      @media screen and (max-width: 991px) {
        width: 100vw;
      }

      @media screen and (min-width: 992px) {
        min-width: 475px;
        width: 30%;
      }
    }

    &.bottom {
      bottom: 0;
      right: 0;
      -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
      transform: translateY(100%);
      border-radius: 25px 25px 0px 0px;

      @media screen and (max-width: 991px) {
        width: 100vw;
      }

      @media screen and (min-width: 992px) {
        min-width: 475px;
        width: 30%;
      }
    }

    &.bottom-half {
      bottom: 0;
      right: 0;
      height: auto;
      max-height: 100%;
      -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
      transform: translateY(100%);
      border-radius: 25px 25px 0px 0px;

      @media screen and (max-width: 991px) {
        width: 100vw;
      }

      @media screen and (min-width: 992px) {
        min-width: 475px;
        width: 30%;
      }
    }

    &.right {
      top: 0;
      bottom: 0;
      right: 0;
      -webkit-transform: translateX(100%);
      -ms-transform: translateX(100%);
      transform: translateX(100%);

      @media screen and (max-width: 991px) {
        width: 100vw;
      }

      @media screen and (min-width: 992px) {
        min-width: 475px;
        width: 30%;
        border-radius: 10px 0 0 10px;
      }

      .content {
        padding-bottom: 50px;
      }
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      min-height: 80px;
      padding: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .back {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 15px;
        margin: auto;
        z-index: 1;
        cursor: pointer;
        background: #f0f0f0;
        border-radius: 15px;
        width: 44px;
        height: 44px;

        &:hover {
          background: #e2e2e2;
        }
      }

      .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: #404040;
      }

      .subtitle {
        font-size: 14px;
        line-height: 20px;
        color: #404040;
        text-align: center;
        margin-top: 10px;
      }

      .close {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 20px;
        margin: auto;
        z-index: 1;
        cursor: pointer;
        background: #f0f0f0;
        border-radius: 15px;
        width: 44px;
        height: 44px;

        &:hover {
          background: #e2e2e2;
        }
      }

      .jump {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 20px;
        margin: auto;
        z-index: 1;
      }
    }

    .content {
      height: 100%;
      width: 100%;
      padding: 20px 20px 25px;
      overflow-y: auto;
      overscroll-behavior: contain;

      &.withoutPadding {
        padding: 0;
      }

      &.withoutScroll {
        overflow-y: hidden;
      }
    }
  }

  &.drawer-base_after-open {
    > .fade {
      opacity: 1;
    }

    > .drawer {
      &.top-full {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
      }

      &.bottom-full {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
      }

      &.bottom {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
      }

      &.bottom-half {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
      }

      &.bottom-center {
        -webkit-transform: translateY(35vh);
        -ms-transform: translateY(35vh);
        transform: translateY(35vh);
      }

      &.right {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
      }
    }
  }

  &.drawer-base_before-close {
    > .fade {
      opacity: 0;
    }

    > .drawer {
      &.bottom-center {
        -webkit-transform: translateY(100vh);
        -ms-transform: translateY(100vh);
        transform: translateY(100vh);
      }

      &.top-full {
        -webkit-transform: translateY(100vh);
        -ms-transform: translateY(100vh);
        transform: translateY(100vh);
      }

      &.bottom-full {
        -webkit-transform: translateY(100%);
        -ms-transform: translateY(100%);
        transform: translateY(100%);
      }

      &.bottom {
        -webkit-transform: translateY(100%);
        -ms-transform: translateY(100%);
        transform: translateY(100%);
      }

      &.bottom-half {
        -webkit-transform: translateY(100%);
        -ms-transform: translateY(100%);
        transform: translateY(100%);
      }

      &.bottom-center {
        -webkit-transform: translateY(100vh);
        -ms-transform: translateY(100vh);
        transform: translateY(100vh);
      }

      &.right {
        -webkit-transform: translateX(100%);
        -ms-transform: translateX(100%);
        transform: translateX(100%);
      }
    }
  }
}

.overlay-base {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
}
