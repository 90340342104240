.addressList {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;

  .address {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;

    &:hover {
      svg path {
        fill: #3d734a;
      }

      p {
        color: #3d734a;
      }
    }

    svg {
      margin-right: 20px;
    }

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
      flex: 1;
    }
  }
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #4d4d4d;
  margin-top: 20px;
  margin-bottom: 20px;
}

.addressCard {
  background: rgba(153, 153, 153, 0.09);
  border-radius: 15px;
  display: flex;
  align-items: stretch;
  border: 1px solid transparent;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &.selected {
    .details {
      border: 1px solid rgba(61, 115, 74, 0.45);

      .icon {
        background: #e7eee9;

        svg path {
          fill: #3d734a;
        }
      }
    }
  }

  &.disabled {
    opacity: 0.6;
    cursor: default;
  }

  .details {
    display: flex;
    padding: 20px;
    border-radius: 15px;
    background: #ffffff;
    z-index: 1;
    width: 100%;
    flex-direction: column;

    .icon {
      background: #f2f2f2;
      min-width: 30px;
      min-height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      border-radius: 10px;
    }

    .alias {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin-bottom: 5px;
    }

    .address {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #999999;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin-right: 10px;
    }

    .unit {
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid #ebebeb;
      display: flex;
      align-items: center;

      .icon {
        width: 25px;
        margin-right: 20px;
        position: relative;
        background: #fff;
      }

      .unitName {
        font-size: 14px;
        line-height: 18px;
        color: #999999;
      }
    }

    .selected {
      display: flex;
      align-items: center;

      .check {
        cursor: pointer;
        padding: 5px;
      }

      .ellipsis {
        cursor: pointer;
        padding: 5px;
        transition: transform 0.25s ease;

        &.rotate {
          transform: rotate(90deg);
          margin-left: 5px;
        }
      }
    }
  }

  .editOrRemove {
    display: none;
    align-items: center;
    justify-content: center;
    padding: 0 10px 0 27px;
    transform: translateX(-15px);
    margin-right: -15px;
    border: 1.5px solid transparent;
    background: #f8f8f8;
    border-radius: 0 15px 15px 0;

    &.visible {
      display: flex;
    }

    .edit {
      padding: 5px;
      cursor: pointer;
    }

    .remove {
      padding: 5px;
      cursor: pointer;
    }
  }

  .deliveryType {
    background: rgba(61, 115, 74, 0.06);
    border-radius: 15px;
    padding: 15px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 15px;

    .name {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #3d734a;
    }

    .deliveryEstimate {
      border: 1px solid rgba(61, 115, 74, 0.25);
      border-radius: 20px;
      font-size: 12px;
      line-height: 22px;
      color: #3d734a;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 15px;
      font-weight: 500;
    }

    .hairline {
      background: rgba(61, 115, 74, 0.15);
      width: 1px;
      height: 32px;
      margin: 0 15px;
    }

    .edit {
      cursor: pointer;
    }
  }
}

.footer {
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  @media screen and (min-width: 992px) {
  }
}

.mapBackground {
  aspect-ratio: 977 / 345;
  position: relative;
  margin-bottom: 20px;
}

.addressName {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #666666;
  margin-bottom: 5px;
}

.fullAddress {
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #b0b0b0;
  margin-bottom: 50px;
}

.shimmerAddress {
  display: flex;
  flex-direction: column;

  .container {
    display: flex;
    align-items: center;
    padding: 10px 0;

    .item {
      height: 20px;
      margin-left: 20px;
      flex: 1;

      &.shimmer {
        animation-name: shimmerEffect;
        background: #ddd;
        background-image: linear-gradient(
          to right,
          #ddd 0%,
          #f9f9f9 20%,
          #ddd 40%,
          #ddd 100%
        );
        background-repeat: no-repeat;
        background-size: 915px auto;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        border-radius: 10px;
      }
    }
  }
}

.storeAddress {
  background: #ffffff;
  box-shadow: 0px 0 30px 10px rgb(153 153 153 / 10%);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;

  .icon {
    background: #f2f2f2;
    min-width: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    border-radius: 10px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #999999;
  }
}

.notFoundNearUnit {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
  }

  span {
    font-size: 14px;
    line-height: 18px;
    color: #828282;
    text-align: center;
  }
}

.emptyAddressess {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 180px);
  text-align: center;

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    margin-top: 20px;
  }
}

.deleteAddress {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: rgba(0, 0, 0, 0.75);
    margin-bottom: 20px;
  }

  span {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 25px;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 200px);
}

.scrollContainer {
  height: calc(100% - 200px);
  overflow-y: auto;
  padding-right: 15px;
  margin-right: -15px;
}

.addAddressContainer {
  height: calc(100% - 110px);
  overflow-y: auto;
  padding-right: 15px;
  margin-right: -15px;
  padding-bottom: 20px;
}

@keyframes shimmerEffect {
  from {
    background-position: -915px 0;
  }
  to {
    background-position: 915px 0;
  }
}

@-webkit-keyframes shimmerEffect {
  from {
    background-position: -915px 0;
  }
  to {
    background-position: 915px 0;
  }
}

.loadingAddressess {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
