.radioContainer {
  position: relative;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;

    &.readOnly {
      pointer-events: none;
    }
  }

  input[type="radio"] {
    display: none;

    &:checked {
      ~ .radio {
        border-color: #3d734a;

        &:after {
          background: #3d734a;
        }
      }
    }
  }

  .radio {
    min-height: 20px;
    min-width: 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: transparent;
    }
  }

  span {
    font-size: 12px;
    line-height: 15px;
    color: rgba(102, 102, 102, 0.7);
    flex: 1;
  }
}
