.extractContainer {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: -1px 25px 32px -9px rgba(15, 15, 15, 0.04);
  border-radius: 20px;
  overflow: hidden;
  margin-top: 20px;
  padding: 20px;

  .event {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .details {
      display: flex;
      flex-direction: column;

      .type {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: #666666;
        margin-bottom: 5px;
      }

      .date,
      .exp {
        font-size: 12px;
        color: rgba(102, 102, 102, 0.7);
      }

      .exp {
        margin-left: 10px;
      }
    }

    .value {
      padding: 10px 20px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      height: 38px;
      white-space: nowrap;
      margin-left: 10px;

      &.input {
        color: rgba(61, 115, 74, 0.75);
        background: rgba(103, 173, 91, 0.08);
      }

      &.output {
        color: rgba(217, 22, 4, 0.55);
        background: rgba(235, 87, 87, 0.08);
      }
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.emptyEvents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    margin-top: 20px;
  }
}
