.tabs {
  display: flex;
  align-items: center;
  list-style: none;
  position: relative;
  overflow: hidden;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .line {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    border-radius: 10px;
    background: #3d734a;
    transition: all 0.25s ease;
    z-index: 2;
  }

  .border {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    border-radius: 10px;
    background: #e6e6e6;
    z-index: 1;
  }

  .tab {
    font-weight: 600;
    font-size: 12px;
    color: rgba(89, 89, 89, 0.55);
    cursor: pointer;
    height: 100%;
    position: relative;
    white-space: nowrap;
    border-bottom: 3px solid #e6e6e6;
    padding: 0 12.5px 15px;
    text-align: center;
    width: 100%;

    &:last-child {
      margin-right: 5px;
    }

    &.active {
      font-weight: 600;
      color: #3d734a;
      position: relative;
    }
  }
}
