.days {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  .day {
    border-radius: 15px;
    padding: 15px;
    background: #ffffff;
    border: 1px solid rgba(153, 153, 153, 0.25);
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 10px;
    }

    &.selected {
      background: #f2f2f2;
      border: 1px solid #f2f2f2;
    }

    p {
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      color: rgba(77, 77, 77, 0.95);
      margin-bottom: 10px;
    }

    span {
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;
      color: rgba(77, 77, 77, 0.55);
    }
  }
}

.times {
  height: calc(100% - 175px);
  overflow-y: auto;
  margin-right: -15px;
  padding-right: 10px;

  .time {
    background: #ffffff;
    border: 1px solid rgba(153, 153, 153, 0.45);
    border-radius: 15px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    p {
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      color: rgba(77, 77, 77, 0.95);
    }
  }
}

.footer {
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  @media screen and (min-width: 992px) {
  }
}
