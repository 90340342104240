.inputSearchContainer {
  position: relative;
  width: 100%;

  input {
    padding: 5px 20px 5px 50px;
    background: transparent;
    border: 1px solid #e0e0e0;
    background: #e0e0e0;
    border-radius: 10px;
    min-height: 48px;
    width: 100%;

    @media screen and (max-width: 991px) {
      font-size: 16px;
    }

    @media screen and (min-width: 992px) {
      font-size: 14px;
    }

    &::-webkit-input-placeholder {
      color: rgba(128, 128, 128, 0.5);
      font-size: 14px;
    }

    &::-moz-placeholder {
      color: rgba(128, 128, 128, 0.5);
      font-size: 14px;
    }

    &:-ms-input-placeholder {
      color: rgba(128, 128, 128, 0.5);
      font-size: 14px;
    }

    &::-ms-input-placeholder {
      color: rgba(128, 128, 128, 0.5);
      font-size: 14px;
    }

    &::placeholder {
      color: rgba(128, 128, 128, 0.5);
      font-size: 14px;
    }

    &:focus {
      border: 1px solid rgba(153, 153, 153, 0.4);
    }
  }

  .search-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    display: flex;
    align-items: center;
  }
}
