.quantityContainer {
  border-radius: 20px;
  min-width: 105px;
  min-height: 40px;
  display: flex;
  align-items: stretch;
  justify-content: center;

  &.hasQuantity {
    background: #fcfcfc;
    border: 1px solid rgba(0, 0, 0, 0.15);

    .minus, .qty {
      opacity: 1;
    }
  }

  .minus,
  .plus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &.disabled {
      opacity: 0.5;
    }

    &.enabled {
      cursor: pointer;
    }
  }

  .qty {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.6);
  }

  .minus, .qty {
    opacity: 0;
  }
}
