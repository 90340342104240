.container {
  background: linear-gradient(
      0deg,
      rgba(91, 91, 91, 0.35),
      rgba(91, 91, 91, 0.35)
    ),
    #3d734a;
  padding: 20px;
  display: flex;
  align-items: flex-end;
  border-radius: 20px;
  position: relative;

  &.disabled {
    background: rgba(203, 203, 203, 0.15);

    .text,
    .value {
      color: #999999;
    }
  }

  .text {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    opacity: 0.55;
  }

  .value {
    font-weight: 800;
    color: #fff;
    font-size: 30px;
    line-height: 40px;

    @media screen and (max-width: 380px) {
      font-size: 25px;
      line-height: 35px;
    }

    span {
      font-weight: 500;
      color: rgba(255, 255, 255, 0.65);
    }
  }

  .goToExtract {
    cursor: pointer;
  }
}
