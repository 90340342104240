.messageContainer {
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 200px;

  &.error {
    background: #fdf3f2;

    .text {
      color: #e97a6f;
    }
  }

  &.success {
    background: #d2ebe6;
  }

  &.caution {
    background: #ebe9d2;
  }

  &.info {
    background: #d2e1eb;
  }

  svg {
    margin-right: 15px;
  }

  .text {
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
}
