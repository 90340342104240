.inputContainer {
  position: relative;

  input,
  textarea {
    &.design-one {
      background: transparent;
      border: 1px solid rgba(153, 153, 153, 0.45);
      border-radius: 10px;
      padding: 0 20px;
      min-height: 48px;
      width: 100%;
      font-size: 16px;
      background: #fff;

      @media screen and (max-width: 991px) {
        font-size: 16px;
      }

      @media screen and (min-width: 992px) {
        font-size: 14px;
      }
    }

    &.design-two {
      background: transparent;
      border: none;
      border-bottom: 1px solid #cccccc;
      padding: 10px 0;
      color: #222;
      width: 100%;
      font-size: 16px;
      min-height: 35px;
      border-radius: 0;

      @media screen and (max-width: 991px) {
        font-size: 16px;
      }

      @media screen and (min-width: 992px) {
        font-size: 14px;
      }
    }

    &::-webkit-input-placeholder {
      color: rgba(128, 128, 128, 0.65);
      font-size: 14px;
    }

    &::-moz-placeholder {
      color: rgba(128, 128, 128, 0.65);
      font-size: 14px;
    }

    &:-ms-input-placeholder {
      color: rgba(128, 128, 128, 0.65);
      font-size: 14px;
    }

    &::-ms-input-placeholder {
      color: rgba(128, 128, 128, 0.65);
      font-size: 14px;
    }

    &::placeholder {
      color: rgba(128, 128, 128, 0.65);
      font-size: 14px;
    }

    &.password {
      padding-right: 50px;
    }

    &.search {
      padding-left: 50px;
    }

    &.invalid {
      border-color: #eb5757;
    }

    &:focus {
      border-color: rgba(0, 0, 0, 0.2);
    }
  }

  textarea {
    border: 1px solid rgba(217, 217, 217, 0.4);
    padding: 20px;
    min-height: 120px;
    background: rgba(217, 217, 217, 0.4);
    border-radius: 15px;
    width: 100%;
  }

  .search-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    display: flex;
    align-items: center;
    z-index: 1;
  }

  .label {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    margin-bottom: 5px;

    @media screen and (max-width: 991px) {
      font-size: 12px;
    }

    &.invalid {
      color: #eb5757;
    }
  }

  label.float {
    pointer-events: none;
    position: absolute;
    top: -9px;
    left: 10px;
    transition: all 0.2s ease-in-out;
    background-color: #fff;
    padding: 0 10px;
    font-size: 12px;
    color: rgba(102, 102, 102, 0.7);
  }

  .eye {
    position: absolute;
    bottom: 15px;
    right: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.error {
  font-weight: 300;
  font-size: 11px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #eb5757;
  margin-top: 5px;
}
