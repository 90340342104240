.modalContainer {
  z-index: 7;
  position: fixed;
  touch-action: none;

  &.open {
    .fade {
      opacity: 1;
      pointer-events: initial;
    }

    .modal {
      opacity: 1;
      pointer-events: initial;

      &.center {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    min-height: 25px;
    padding-bottom: 10px;
    margin-bottom: 10px;

    .back {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      margin: auto;
      cursor: pointer;
    }

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      margin: auto;
      cursor: pointer;
    }
  }

  .fade {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 0;
    transition: all 0.25s ease;
    pointer-events: none;
    touch-action: none;

    @media screen and (max-width: 991px) {
      background: rgba(0, 0, 0, 0.5);
    }

    @media screen and (min-width: 992px) {
      background: rgba(0, 0, 0, 0.2);
    }

    &.withBackground {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .modal {
    transition: all 0.25s ease;
    z-index: 1002;
    width: calc(100% - 30px);
    height: 100%;
    margin: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    display: flex;
    align-items: center;
    pointer-events: none;

    .container {
      background: #f2f2f2;
      padding: 20px 20px 0;
      border-radius: 15px;
      width: 100%;
    }

    &.center {
      -webkit-transform: translateY(-50px);
      -ms-transform: translateY(-50px);
      transform: translateY(-50px);

      .content {
        height: 100%;
        padding-right: 10px;
        margin-right: -15px;
        padding-bottom: 15px;
        display: flex;
        flex-direction: column;
        overscroll-behavior: contain;
        width: 100%;
      }
    }
  }
}
