.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 80px;
  padding: 15px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fcfcfc;
  z-index: 1;

  @media screen and (min-width: 992px) {
    display: none;
  }

  .back {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #dbdbdb;
    border-radius: 10px;
    width: 42px;
    height: 42px;
    margin-right: 15px;

    &:hover {
      background: #e2e2e2;
    }
  }

  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #666666;
  }
}

.scrollContainer {
  margin-top: 20px;
  padding-bottom: 120px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .greetings {
    border-bottom: 1px solid #e4e4e4;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 30px;

    p {
      font-weight: 800;
      font-size: 24px;
      line-height: 30px;
      color: rgba(0, 0, 0, 0.65);
      margin-left: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      flex: 1;
    }
  }

  ul {
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      align-items: center;
      padding-top: 30px;
      padding-bottom: 30px;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 0.5px solid #d9d9d9;
      }

      &:hover {
        svg path {
          fill: #3d734a;
          fill-opacity: 1;
        }

        span {
          color: #3d734a;
        }
      }

      .icon {
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #737373;
        margin-left: 20px;
      }
    }
  }
}

.logout {
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: rgba(0, 0, 0, 0.75);
    margin-bottom: 25px;
  }
}
