.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #4d4d4d;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 14px;
  line-height: 22px;
  color: rgba(89, 89, 89, 0.65);
  margin-bottom: 20px;
}

.QRCode {
  padding: 25px 25px 20px;
  border-radius: 15px;
  background: #fff;

  .QRCodeContainer {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }

  .item {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(111, 118, 126, 0.18);
    }

    .code {
      background: #ffffff;
      border: 1.5px dashed rgba(0, 0, 0, 0.26);
      border-radius: 10px;
      padding: 15px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 5px;

      .copy {
        cursor: pointer;
      }
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.3);
      margin-bottom: 10px;
    }

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  &.blurred {
    position: relative;
    margin: 0 -20px;
    height: 100%;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    > * {
      filter: blur(5px);
    }
  }
}
