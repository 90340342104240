.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;

  &:hover:not(:disabled) {
    cursor: pointer;
  }

  &.default {
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #3d734a;
    padding-bottom: 3px;
    border-bottom: 1.5px solid transparent;

    &:hover:not(:disabled) {
      border-bottom: 1.5px solid #3d734a;
    }
  }

  &.default-without-underline {
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #3d734a;

    &:hover:not(:disabled) {
      opacity: 0.8;
    }
  }

  &.default-white {
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    padding-bottom: 3px;
    border-bottom: 1.5px solid transparent;

    &:hover:not(:disabled) {
      border-bottom: 1.5px solid #fff;
    }
  }

  &.secondary-bold {
    border: none;
    font-weight: 600;
    font-size: 12px;
    color: #b3b3b3;
  }

  &.primary {
    background: #3d734a;
    border-radius: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    height: 60px;
    border: none;
    padding: 20px;
    width: 100%;

    &:hover:not(:disabled) {
      background: linear-gradient(
          0deg,
          rgba(91, 91, 91, 0.35),
          rgba(91, 91, 91, 0.35)
        ),
        #3d734a;
      cursor: pointer;
    }

    &:disabled {
      background: #bfcbc2;
      color: #fff;
    }
  }

  &.secondary {
    background: rgba(61, 115, 74, 0.15);
    border-radius: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #3d734a;
    height: 60px;
    border: none;
    padding: 20px;
    width: 100%;
    min-width: 60px;

    &:hover:not(:disabled) {
      background: rgba(61, 115, 74, 0.1);
    }

    &:disabled {
      background: #bfcbc2;
      color: #fff;
    }
  }

  &.underline {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #737373;
    border: none;
    padding-bottom: 3px;
    border-bottom: 1.5px solid transparent;

    &:hover:not(:disabled) {
      border-bottom: 1.5px solid #737373;
    }
  }

  &.underline-grey {
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #828282;

    line-height: 18px;

    border: none;
  }

  .loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    .loader {
      width: 15px;
      height: 15px;
      border: 1.5px solid #fff;
      border-bottom-color: transparent;
      border-radius: 50%;
      animation: rotation 1s linear infinite;
    }
  }

  @-webkit-keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
