.home {
  position: relative;

  .contentLoading {
    display: none;
  }
}

.receiveOnOptions {
  padding: 35px 20px 0;
  overflow-y: auto;
  height: calc(100% - 100px);

  @media screen and (min-width: 992px) {
    padding: 35px 50px 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .option {
    border-radius: 30px;
    padding: 30px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @for $i from 1 through 3 {
      &:nth-child(1n + #{$i}) {
        animation: animateTopTop 0.5s both $i * 0.15s;
      }
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &.selected {
      background: rgba(61, 115, 74, 0.15);
      border: 1px solid transparent;

      .icon {
        background: rgba(61, 115, 74, 0.15);

        svg {
          width: 30px;
          height: 30px;

          path {
            fill: rgba(61, 115, 74, 1);
          }
        }
      }

      .name {
        color: #3d734a;
      }
    }

    .icon {
      width: 65px;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      background-color: #f0f0f0;
      margin-bottom: 20px;

      svg {
        transition: all 0.25s ease;
      }
    }

    .name {
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
      color: #4d4d4d;
    }

    .inUse {
      background: #3d734a;
      position: absolute;
      left: 20px;
      top: 20px;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-weight: 600;
      font-size: 10px;
      line-height: 20px;
      border-radius: 20px;
      text-transform: uppercase;
    }
  }
}

.locationOptions {
  padding: 35px 20px 0;
  overflow-y: auto;
  height: calc(100% - 100px);

  @media screen and (min-width: 992px) {
    padding: 35px 50px 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .option {
    border-radius: 30px;
    padding: 30px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;

    @for $i from 1 through 3 {
      &:nth-child(1n + #{$i}) {
        animation: animateTopTop 0.5s both $i * 0.15s;
      }
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &.selected {
      background: rgba(61, 115, 74, 0.15);
      border: 1px solid transparent;

      .icon {
        background: rgba(61, 115, 74, 0.15);

        svg {
          width: 30px;
          height: 30px;

          path {
            fill: rgba(61, 115, 74, 1);
          }
        }
      }

      .name {
        color: #3d734a;
      }
    }

    .icon {
      width: 65px;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      background-color: #f0f0f0;
      margin-bottom: 20px;

      svg {
        transition: all 0.25s ease;
      }
    }

    .name {
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
      color: #4d4d4d;
    }
  }
}

.scanQRcodeContainer {
  background: #f5f5f5;
  padding: 70px 35px 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;

  @media screen and (min-width: 992px) {
    padding: 70px 50px 35px;
  }

  .scanQRcode {
    background: #ffffff;
    border-radius: 25px;
    padding: 70px 15px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    animation: animateTopTop 0.5s both;

    .logo {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      position: absolute;
      top: -30px;
      z-index: 1;
      background: #fff;
      padding: 5px;

      .picture {
        height: 50px;
        position: relative;

        img {
          object-fit: contain;
        }
      }
    }

    .mobilePicture {
      height: 200px;
      width: 100%;
      position: relative;

      img {
        object-fit: contain;
      }
    }

    p {
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.45);
      text-align: center;
      margin-top: 30px;

      span {
        color: #3d734a;
      }
    }
  }
}

.startContainer {
  z-index: 1;
  background: #fff;
  border-radius: 25px 25px 0 0;
  padding: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (min-width: 992px) {
    display: none;
  }

  .title {
    color: #404040;
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 30px;

    strong {
      color: #3d734a;
    }
  }

  .subtitle {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 35px;
    color: rgba(0, 0, 0, 0.45);
  }

  .start {
    background: #3d734a;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    padding: 15px 20px;
    display: flex;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      margin-left: 10px;
    }
  }
}

.alertTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #404040;
  margin-bottom: 5px;
}

.alertSubtitle {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 25px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.footer {
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  animation: animateTopTop 0.5s both;
  z-index: 1;

  @media screen and (min-width: 992px) {
  }
}

@keyframes animateTopTop {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
