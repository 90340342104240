.coupon {
  padding: 20px;
  border-radius: 15px;
  position: relative;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;

  &:not(.disabled) {
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.5;
  }

  &.selected {
    border: 1px solid #3d734a;

    .couponDetails {
      .name {
        color: #3d734a;
      }
    }
  }

  .couponDetails {
    flex: 1;
    display: flex;
    flex-direction: column;

    .name {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
      margin-bottom: 5px;
    }

    .code {
      font-size: 12px;
      line-height: 20px;
      color: rgba(102, 102, 102, 0.7);
    }
  }

  .requirements {
    border-top: 1.5px dashed rgba(111, 118, 126, 0.3);
    margin-top: 15px;
    padding-top: 15px;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.35);
  }

  .seeRulesShow,
  .seeRulesHide {
    font-size: 12px;
    line-height: 16px;
    color: #828282;
    cursor: pointer;

    svg {
      transition: transform 0.2s ease;
      margin-left: 10px;
    }
  }

  .seeRulesShow {
    svg {
      transform: rotate(180deg);
    }
  }

  .seeRulesHide {
    svg {
      transform: rotate(0);
    }
  }

  .rules {
    flex-direction: column;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.35);
    overflow: hidden;
    transition: max-height 0.5s ease;

    &.hide {
      max-height: 0;
    }

    &.show {
      max-height: 130px;
    }

    .title {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: rgba(0, 0, 0, 0.55);
      list-style: none;
      margin-top: 15px;
      margin-bottom: 20px;
    }

    li:not(.title) {
      list-style: inside;
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;
      color: rgba(0, 0, 0, 0.35);

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  .notAvailable {
    background: #fafafa;
    border-radius: 0px 0px 15px 15px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    color: #828282;
    margin: 10px -20px -20px;
  }
}
