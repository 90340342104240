.loseBenefits {
  .info {
    background: #f5f5f5;
    border-radius: 25px;
    width: 95px;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px;
  }

  .text {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 5px;
  }

  .benefits {
    margin-top: 35px;
    list-style: none;

    li {
      background: #f5f5f5;
      border-radius: 25px;
      font-size: 14px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.5);
      padding: 20px 20px 20px 40px;
      margin-bottom: 5px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 25px;
        top: 0;
        bottom: 0;
        width: 4px;
        height: 4px;
        background: rgba(0, 0, 0, 0.5);
        margin: auto;
        border-radius: 50%;
      }
    }
  }
}

.scrollContainer {
  overflow-y: auto;
  padding-top: 10px;
  padding-right: 15px;
  margin-right: -15px;
}

.footer {
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  @media screen and (min-width: 992px) {
  }
}

.phoneAndBirthdate {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;

  @media screen and (max-width: 380px) {
    flex-direction: column;
  }

  .phone {
    width: 100%;
    margin-right: 10px;

    @media screen and (max-width: 380px) {
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}
