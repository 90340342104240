.paymentMethod {
  background: #f2f2f2;
  border-radius: 20px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .icon {
    background: rgba(0, 0, 0, 0.06);
    margin-right: 15px;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
  }
}

.createAccount {
  background: rgba(0, 0, 0, 0.06);
  margin-top: 20px;
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 50px;

  p {
    color: #666666;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
  }

  span {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
    font-weight: 500;
    display: block;
    color: rgba(0, 0, 0, 0.35);
  }
}

.alertTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #404040;
  margin-bottom: 5px;

  span {
    color: #3d734a;
  }
}

.alertSubtitle {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 25px;
}

.footer {
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  @media screen and (min-width: 992px) {
  }
}

.processingPayment {
  .brandHeader {
    border-radius: 0 0 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    background: #ebebeb;
    margin-bottom: 30px;
    min-height: 200px;

    .picture {
      width: 100px;
      height: 100px;
      position: relative;
      border-radius: 50%;
      overflow: hidden;

      img {
        object-fit: contain;
      }
    }
  }

  .statusContainer {
    .msgOne {
      color: #404040;
      font-weight: 600;
      line-height: 22px;
      text-align: center;
      opacity: 1;
      transform: translateY(10px);
      animation: toTop 5s ease 2.5s infinite;
    }

    .msgTwo {
      color: #404040;
      font-weight: 600;
      line-height: 22px;
      text-align: center;
      opacity: 0;
      transform: translateY(10px);
      animation: toBottom 5s ease 2.5s infinite;
    }
  }

  .loader {
    width: 200px;
    margin: auto;
    display: flex;
    justify-content: center;
    height: 3px;
    position: relative;
    background: #d6d6d6;
    overflow: hidden;
    margin-top: 20px;

    &::after {
      content: "";
      width: 200px;
      height: 3px;
      background: #aaa;
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      animation: animloader 3s ease 0.1s infinite;
    }
  }
}

@keyframes animloader {
  0% {
    transform: translateX(-200px);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes toBottom {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  33% {
    opacity: 1;
    transform: translateY(-10px);
  }
  66% {
    opacity: 1;
    transform: translateY(-10px);
  }
  100% {
    opacity: 0;
    transform: translateY(10px);
  }
}

@keyframes toTop {
  0% {
    opacity: 1;
    transform: translateY(10px);
  }
  33% {
    opacity: 0;
    transform: translateY(0);
  }
  66% {
    opacity: 0;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(10px);
  }
}
