.scrollContainer {
  overflow-y: auto;
  padding-right: 15px;
  margin-right: -15px;
  padding-bottom: 30px;

  @media screen and (min-width: 992px) {
    height: calc(100vh - 100px);
  }

  .addCoupon {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 20px;

    .message {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .icon {
        padding-right: 20px;
        margin-right: 20px;
        border-right: 0.5px solid #b3b3b3;
        display: flex;
        align-items: center;
      }

      p {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #828282;
      }
    }
  }

  .coupon {
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      border-radius: 50%;
      background: #fcfcfc;
      border: 1.5px dashed rgba(0, 0, 0, 0.075);
      top: 0;
      bottom: 0;
      left: -15px;
      z-index: 1;
      width: 30px;
      height: 30px;
      margin: auto;
    }

    &:after {
      content: "";
      position: absolute;
      border-radius: 50%;
      background: #fcfcfc;
      border: 1.5px dashed rgba(0, 0, 0, 0.075);
      top: 0;
      bottom: 0;
      right: -15px;
      z-index: 1;
      width: 30px;
      height: 30px;
      margin: auto;
    }

    .details {
      border-radius: 25px;
      border: 1.5px dashed rgba(0, 0, 0, 0.1);
      padding: 20px 35px;
      background: #ffffff;

      .name {
        font-weight: 800;
        font-size: 20px;
        line-height: 25px;
        color: #525252;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      .code {
        background: rgba(61, 115, 74, 0.2);
        border-radius: 30px;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 15px;
        color: #3d734a;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .exp {
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        color: #666666;
      }

      .requirement {
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
        color: #666666;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding-top: 15px;
        margin-top: 15px;
      }
    }
  }
}

.footer {
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  @media screen and (min-width: 992px) {
  }
}
