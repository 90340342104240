.verificationCodeContainer {
  position: relative;

  .label {
    display: block;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #333333;
    margin-bottom: 15px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    place-items: center;
    gap: 10px;

    input {
      background: #ffffff;
      border: 1px solid #ccc;
      border-radius: 8px;
      height: 43px;
      width: 43px;
      padding: 5px 15px;
      color: #000;
      font-size: 12px;
      font-weight: bold;
      text-align: center;

      &:focus {
        border-color: #555;
      }
    }
  }
}
