.steps {
  display: flex;
  align-items: center;
  list-style: none;
  position: relative;
  overflow: auto;
  margin-bottom: 5px;

  &::-webkit-scrollbar {
    display: none;
  }

  .line {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 3px;
    border-radius: 10px;
    background: #3d734a;
    transition: all 0.25s ease;
  }

  li {
    padding: 15px 12.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: rgba(89, 89, 89, 0.4);
    font-size: 12px;
    white-space: nowrap;
    line-height: 15px;
    height: 100%;
    width: 100%;
    border-top: 3px solid rgba(61, 115, 74, 0.05);

    &.completed {
      cursor: pointer;
      color: #3d734a;
    }

    .dot {
      width: 3px;
      height: 3px;
      background: #3d734a;
      border-radius: 10px;
      margin-right: 5px;

      &.show {
        opacity: 1;
      }

      &.hide {
        opacity: 0;
      }
    }
  }
}
