.cartResumeContainer {
  background: #3d734a;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  padding: 5px 25px;
  width: calc(100% - 30px);
  margin: auto;
  width: 100%;
  position: relative;
  margin-bottom: 15px;

  @media screen and (min-width: 992px) {
    display: none;
  }

  .seeCart,
  .totalCart {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }

  .totalCart {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  .numberOfItems {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 25px;
    background: #ffffff;
    border-radius: 400px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #3d734a;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    margin: auto;
    min-width: 28px;
  }
}
