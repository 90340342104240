.brands {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  background: #f0f0f0;
  padding: 35px 15px;
  grid-auto-rows: max-content;
  min-height: 100%;

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
    padding: 35px 50px;
  }

  .brand {
    box-shadow: -1px 25px 32px -9px #0f0f0f0a;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    overflow: hidden;
    padding: 15px;

    @for $i from 1 through 100 {
      &:nth-child(1n + #{$i}) {
        animation: animateTopTop 0.5s both $i * 0.15s;
      }
    }

    .picture {
      position: relative;
      height: 100px;
      width: 100%;

      img {
        object-fit: contain;
      }
    }

    .brandName {
      color: #4d4d4d;
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
      margin-top: 15px;
    }
  }
}

@keyframes animateTopTop {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
