.loginOrRegister {
  overflow-y: auto;
  overscroll-behavior: contain;
  width: calc(100% - 20px);
  background: #fff;
  border-radius: 15px;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    right: 20px;
    margin: auto;
    z-index: 1;
    cursor: pointer;
    background: #f7f7f7;
    border-radius: 10px;
    width: 42px;
    height: 42px;

    &:hover {
      background: #e2e2e2;
    }
  }

  .storeLogo {
    width: 200px;
    height: 200px;
    position: relative;
    margin: auto;
    margin-bottom: 30px;
    border-radius: 50%;
    overflow: hidden;

    img {
      object-fit: cover;
    }
  }

  .storeName {
    font-weight: 600;
    font-size: 26px;
    line-height: 33px;
    text-align: center;
    color: #000000;
    margin-bottom: 65px;

    @media screen and (max-width: 380px) {
      margin-bottom: 40px;
    }
  }
}

.scrollContainer {
  height: calc(100% - 50px);
  padding-top: 20px;
  padding-right: 15px;
  margin-right: -20px;
  overflow-y: auto;
}

.recoverPassword {
  p {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
  }
}

.footer {
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  @media screen and (min-width: 992px) {
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.customerDataContainer {
  padding-top: 15px;
}

.phoneAndBirthdate {
  display: flex;
  margin-bottom: 20px;

  @media screen and (max-width: 380px) {
    flex-direction: column;
  }

  .phone {
    width: 100%;
    margin-right: 10px;

    @media screen and (max-width: 380px) {
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}

.alertTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #404040;
  margin-bottom: 50px;
}

.alertSubtitle {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 25px;
}
